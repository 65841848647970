import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Button from '../components/Button';
import Text from '../components/Text';
import { fetchAdequatePixel } from '../utils/datautils';
import counselorsMini from '../assets/counselors_mini.png';
import tarotCards from '../assets/tarot_cards.png';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  /* or 162% */
  background: none;
  letter-spacing: 0.15px;

  /* gray 9 */
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const CounselorsMini = styled.img`
  margin: 2.7rem 0;
  width: 30rem;
  height: 10rem;
`;

const TarotCards = styled.img`
  margin: 2.7rem 0;
  width: 31.9rem;
  height: 21rem;
`;

const InputWorryPage = ({ value, onChange, onButtonClick }) => {
  const pixelId = fetchAdequatePixel();
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });
  }, []);

  return (
    <>
      <CounselorsMini src={counselorsMini} />
      <Text style={{ width: '100%' }}>
        상위 1% 상담사가
        <br /> <span style={{ color: '#E1354C' }}>무료로,</span> 100분에게만
        <br />
        타로를 봐드립니다.
      </Text>
      <TarotCards src={tarotCards} />
      <Text>
        {value.length === 0 && <span style={{ color: '#E1354C' }}>고민을 30자 이상 적어주세요</span>}
        {value.length <= 30 && value.length !== 0 && value.length !== 30 && (
          <span style={{ color: '#E1354C' }}>{`${30 - value.length}자 더 부탁드려요`}</span>
        )}
      </Text>

      <Input placeholder={'고민 내용을 입력해주세요...'} value={value} onChange={onChange} />
      <div
        style={{
          marginTop: '1.6rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          id="cta-finish-writing"
          text="입력 완료"
          width="50%"
          color="#fff"
          backgroundColor={value.length >= 30 ? '#222529' : '#CFD4DA'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
          }}
          onClickHandler={
            value.length >= 30
              ? () => {
                  fbq('trackSingle', pixelId, 'SubmitApplication');
                  onButtonClick();
                }
              : () => {}
          }
        />
      </div>
    </>
  );
};

export default InputWorryPage;
