import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Datetime from 'react-datetime';
import 'moment/locale/ko';
import Button from '../../components/Button';
import Text from '../../components/Text';

import './index.css';

const ReservePage = ({ onButtonClick, onDateChange }) => {
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude.getInstance().logEvent('view_reservation', {
      referral: 'postpay_painful_v3',
    });
  }, []);

  const buttonClickHandler = () => {
    onButtonClick();
    amplitude.getInstance().logEvent('click_reservation', {
      referral: 'postpay_painful_v3',
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <Text style={{ width: '100%', marginTop: '1.6rem' }}>
        그렇다면,
        <br />
        예약을 잡아드릴게요.
      </Text>
      <div
        style={{
          marginTop: '2.8rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <Datetime
          initialValue={new Date()}
          onChange={date => {
            onDateChange(prev => date);
          }}
        />
        <SubText>▲ 눌러서 시간 변경하기 ▲</SubText>
      </div>
      <SubText>
        ** 예약하신 시간에 선생님이
        <br />
        전화를 주실 거에요.
      </SubText>
      <div
        style={{
          width: '100%',
          position: 'fixed',
          padding: '0 2rem',
          bottom: '1.6rem',
          left: 0,
          right: 0,
        }}>
        <Button
          width="100%"
          backgroundColor="#222529"
          text="예약하기"
          color="#fff"
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '2rem',
            textAlign: 'center',
          }}
          onClickHandler={buttonClickHandler}
        />
      </div>
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 180%;
  color: #2e1717;
  margin-top: 1.6rem;
`;

export default ReservePage;
