import { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import moment from 'moment';
import Text from '../components/Text';
import checkedButton from '../assets/checked.svg';
import unCheckedButton from '../assets/unChecked.svg';
import { IS_TEST } from '../constants';
import Button from '../components/Button';
import { fetchUtmMap, setUserId, UtmKey } from '../utils/datautils';
import 'moment/locale/ko';

const REGISTER_STATE = {
  init: 'INIT',
  loading: 'LOADING',
  complete: 'COMPLETE',
  duplicated: 'DUPLICATED',
  error: 'ERROR',
};

const InputPhonePageReservation = ({ reservationDate, worry, onReady }) => {
  const [phone, setPhone] = useState('');
  const [submited, setSubmited] = useState(false);

  const { _d: reservedDate } = reservationDate;
  const inputPhoneChangeHandler = event => {
    setPhone(prev => event.target.value);
  };

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude.getInstance().logEvent('view_input_phone_reservation', { referral: 'postpay_painful_v3' });
  }, []);

  const [isChecked, setIsChecked] = useState(true);
  const [registerState, setRegisterState] = useState(REGISTER_STATE.init);

  const URL = IS_TEST
    ? 'https://test.tarosister.com/api/v1/user/register'
    : `https://api.tarosister.com/api/v1/user/register`;

  const userRegisterHandler = async () => {
    if (!isPhoneNumValid(phone) || !isChecked) return;
    if (submited) return;

    amplitude.getInstance().logEvent('click_input_phone_reservation', {
      phone,
      referral: 'postpay_painful_v3',
    });

    setRegisterState(REGISTER_STATE.loading);

    let utmMap = {};

    try {
      setUserId(phone);
      try {
        if (localStorage.getItem(UtmKey)) {
          utmMap = JSON.parse(localStorage.getItem(UtmKey));
        } else {
          utmMap = await fetchUtmMap();
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      // Todo

      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          phone,
          referral: 'postpay_painful_v3',
          reserved_at: reservedDate || moment().format(),
          cards: [24, 24, 24],
          worry,
          is_test: IS_TEST,
          ...utmMap,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmited(prev => true);
        setRegisterState(REGISTER_STATE.complete);
        const identify = new amplitude.Identify().setOnce('phone', phone);
        amplitude.getInstance().identify(identify);
        onReady();
      } else if (response.status === 400) {
        amplitude.getInstance().logEvent('already_registered', {
          phone,
          referral: 'postpay_painful_v3',
        });
        setRegisterState(REGISTER_STATE.duplicated);
        setTimeout(() => {
          window.open(`https://tarot-sister.com/${window.location.search}`, '_self');
        }, 2000);
      } else {
        setRegisterState(REGISTER_STATE.error);
        throw new Error(`${response.status} error`);
      }
    } catch (error) {
      Sentry.captureException(error);
      Sentry.captureMessage(error.message);
    }
  };

  const checkBoxChangeHandler = event => {
    setIsChecked(prev => !prev);
  };

  const isPhoneNumValid = phoneNum => phoneNum.length === 11;

  return (
    <>
      <Text style={{ marginTop: '1.6rem', width: '100%' }}>
        연락 받으실 <br />
        휴대폰 번호를 알려주세요
      </Text>
      <Input
        placeholder="휴대폰 번호 입력"
        value={phone}
        onChange={inputPhoneChangeHandler}
        inputMode="numeric"></Input>

      {isPhoneNumValid(phone) && (
        <AgreeContainer>
          <CheckBox type="checkbox" id="checkbox" checked={isChecked} onChange={checkBoxChangeHandler} />
          <CheckBoxLabel htmlFor="checkbox">
            <LabelContainer>
              <LabelTxtMain>(필수) 마케팅 수집 이용 동의</LabelTxtMain>
              <LabelTxtSub>
                선생님이 상담 전화를 드리려면 반드시 필요합니다. 여러분들의 상담을 위한 용도 이외에 어떤 메세지도 보내지
                않습니다.
              </LabelTxtSub>
            </LabelContainer>
            <RealCheckBox></RealCheckBox>
          </CheckBoxLabel>
        </AgreeContainer>
      )}
      {registerState === REGISTER_STATE.loading && (
        <p style={{ color: '#000', fontSize: '1.5rem', marginTop: '1.6rem' }}>등록 중...</p>
      )}
      {registerState === REGISTER_STATE.error && (
        <p style={{ color: '#E1354C', fontSize: '1.5rem', marginTop: '1.6rem' }}>
          오류가 발생했습니다. 잠시 후 다시 시도해주세요
        </p>
      )}
      {registerState === REGISTER_STATE.duplicated && (
        <p style={{ color: '#E1354C', fontSize: '1.5rem', marginTop: '1.6rem' }}>이미 등록된 전화번호입니다.</p>
      )}
      <Button
        id="cta-registration-coupon"
        width="90%"
        backgroundColor={isPhoneNumValid(phone) && isChecked ? '#222529' : '#CFD4DA'}
        text="무료 상담 예약하기"
        color="#fff"
        style={{
          position: 'fixed',
          bottom: '1.6rem',
          left: 0,
          right: 0,
          margin: '0 auto',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '2rem',
          textAlign: 'center',
        }}
        onClickHandler={phone.length === 11 && isChecked ? userRegisterHandler : () => {}}
      />
    </>
  );
};

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #362f2d;
  height: 7.2rem;
  width: 100%;
  padding: 0.8rem 1.2rem 0.8rem 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: none;
  margin-top: 1.6rem;

  &::placeholder {
    color: #7f7573;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid #362f2d;
  }
`;

const AgreeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckBoxLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 5.3rem;
  margin-top: 2rem;
  /* width: 27.6rem; */
`;

const LabelTxtMain = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  display: inline-block;
  padding-right: 2rem;

  display: flex;
  align-items: center;
  color: #4b5057;
`;

const LabelTxtSub = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 180%;
  margin-top: 0.4rem;
  display: inline-block;
  padding-right: 2rem;
  color: #4b5057;
`;

const RealCheckBox = styled.div`
  display: inline-block;
  align-self: flex-start;
  margin-top: 2rem;
  width: 1.8rem;
  height: 1.8rem;
  background-image: url(${unCheckedButton});
  background-size: cover;
`;

const CheckBox = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;

  &:checked {
    & + ${CheckBoxLabel} ${RealCheckBox} {
      background-image: url(${checkedButton});
      background-size: cover;
    }
  }
`;

export default InputPhonePageReservation;
