import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Text from '../components/Text';
import counselors from '../assets/counselors.png';
import { fetchAdequatePixel } from '../utils/datautils';

const Ready = ({ isReserved }) => {
  useEffect(() => {
    const pixelId = fetchAdequatePixel();
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    if (isReserved) {
      amplitude.getInstance().logEvent('reservation', { referral: 'postpay_painful_v3' });
      fbq('trackSingle', pixelId, 'Schedule');
    } else {
      amplitude.getInstance().logEvent('registration', { referral: 'postpay_painful_v3' });
    }

    gtag('event', 'conversion', { send_to: 'AW-10943576545/OUoJCMfEoOUDEOHzpuIo' });
    fbq('trackSingle', pixelId, 'CompleteRegistration');
    ttq.track('CompleteRegistration');
  }, [isReserved]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '2.5rem' }}>
        [<span style={{ color: '#E1354C' }}> 02-6953-6510 </span>]
      </SubText>
      <Text style={{ textAlign: 'center' }}>
        {isReserved ? '예약하신 시간에' : '곧'}
        {isReserved ? <br /> : null} 위 번호로 상담사님의 <br />
        상담 전화가 올거에요
      </Text>
      <SubText>
        전화를 받지 않으시면, 상담사님의 <br /> 소중한 무료 상담 시간을 낭비하게 되니
        <br />
        다른 내담자를 위해서라도 꼭 받아주세요
      </SubText>
      <Counselors src={counselors} />
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  margin-top: 1.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 180%;
  text-align: center;
  color: #2e1717;
`;

const Counselors = styled.img`
  width: 30rem;
  height: 19rem;
  border-radius: 2.4rem;
  margin-top: 1.6rem;
`;

export default Ready;
